import React from "react"
import LayoutFullscreen from "../components/layoutFullscreen"

export default function PageNotFound() {
  return (
    <LayoutFullscreen>
        {/* PAGE NOT FOUND */}
        <h1 style={{textAlign: 'center', marginTop: '30vh'}}>Page not found...</h1>
    </LayoutFullscreen>
    )
}